<template>
    <b-container>
        <div class="content-detail-privilege">
            <div class="text-center" v-if="detail.image_path">
                <img :src="detail.image_path" class="w-100"/> 
            </div>
            <div class="text-center" v-else>
                <img :src="img" class="w-100"/> 
            </div>
            <div class="p-2" style="background-color: #3a3a3a;">
                <div class="title-detail line-clamp-2">
                    {{detail.name}}
                </div>
                <b-row>
                    <b-col>
                        <div class="valide-until">วันหมดอายุ: {{dateFormat(detail.valid_to)}}</div>
                    </b-col>
                </b-row>
            </div>
            <div class="privilege-box-detail description">
                 <div class="sub-description">รายละเอียด</div>
                <div class="p-3" v-html="detail.description"></div>
            </div>
        </div>
    </b-container> 
</template>

<script>
import picture from "@/assets/images/default-image.png";
import moment from 'moment';
export default {
    props:{
        detail:{
            required:true,
            type:Object
        },
    },
    data(){
        return{
            img : picture,
            msg : ""
        }
    },
    methods:{
        dateFormat(date){
            return moment(date).locale('th').format('lll').replace("เวลา","");
        },
    }
}
</script>

<style lang="scss" scoped>
    .status-reward{
        color: lightgreen;
        font-weight: 600;
        font-size: 13px;
        text-align: right;
    }
    .status-false{
        color: red;
        font-weight: 600;
        font-size: 13px;
        text-align: right;
    }
    .title-detail{
        color: #fff;
        font-size: 18px;
        margin: 5 0px;
    }
    .line-clamp-2{
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
    }
    .valide-until{
        color: #fff;
        font-size: 13px;
    }
    .receive-point{
        color: #fff;
        text-align: right;
        font-size: 12px;
    }
    .privilege-box-detail{
        background-color: #fff;
        // height: 16em;
    }
    ::v-deep .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{
        color: #000;
        font-weight: 600;
        border-bottom: 4px solid #000;
    }
    ::v-deep a {
        color : gray;
    }
    ::v-deep li.nav-item{
        width: 50%;
        text-align: center;
    }
    .footer-bottom{
        position: relative;
    }
    .btn-redeem{
        position: fixed;
        width: 90%;
        bottom: 10px;
        left: 20px;
        right: 10px;
        border: none;
        background-color: #000;
        color: #fff;
        padding: 10px 0;
    }
    .content-detail-privilege{
         margin: 1rem;
    }
    .footer{
        position: fixed;
        bottom: 0;
        width: 100%;
        left: 15px;
        right: 0;
        padding: 10px;
        background: #fff;
    }
    .btn-gray{
        background: #000;
        border-color: transparent;
        color: #fff;
    }
    .description{
        background-color: #fff;
    }
    .sub-description{
        padding: 10px;
        border-bottom: 1px solid gray;
        font-weight: 600;
    }
    ::v-deep p img{
        width: 100% !important;
        height: auto !important;
    }
    ::v-deep img{
        width: 100%!important;
        height: auto !important;
    }
    ::v-deep table{
        width: 100% !important;
        height: auto !important;
    }
    ::v-deep tr{
        height: auto !important;
    }
    ::v-deep td{
        height: auto !important;
    }
</style>